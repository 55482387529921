import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#deaa87',
        secondary: '#e9c6af',
        accent: '#f4e3d7',
        error: '#c87137',
        info: '#d38d5f',
        success: '#502d16',
        warning: '#a05a2c',
      },
      dark: {
        primary: '#deaa87',
        secondary: '#e9c6af',
        accent: '#f4e3d7',
        error: '#c87137',
        info: '#d38d5f',
        success: '#502d16',
        warning: '#a05a2c',
      },
    },
  }
});
